/* eslint-disable */
import axios from 'axios'

const instance = axios.create({
  baseURL: '/',
  timeout: 100000,
  // withCredentials: true,
  headers: {},
})

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // config.headers.common['authToken'] = sessionStorage.getItem('token');
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(error.message);
    // console.log(error.response);
    return Promise.reject(error)
  }
)

export default instance;

/* eslint-disable no-new */